@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

.nameFont {
  font-family: "Arial", cursive;
  font-weight: 700;
}

.blur {
  backdrop-filter: blur(6px);
}

.noblur {
  backdrop-filter: blur(0px);
}

.blur2 {
  backdrop-filter: blur(10px);
}

.mainImage {
  height: 400px;
}

.mainImagesm {
  height: 250px;
}

a[href="#"] {
  display: none;
}
/* 
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  cursor: pointer;
  background-color: rgba(229, 231, 235, var(--bg-opacity));
}
::-webkit-scrollbar-track {
  background-color: #2d3748;
  cursor: pointer;
}
::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #38b2ac;
  border-radius: 50%;
  outline: 1px solid slategrey;
} */
